.About {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 70rem;
}

#Info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#InfoText {
    display: flex;
    flex-direction: column;
}

#InfoName {
    font-size: 50px;
    font-weight: 500;
    margin-bottom: .5rem;
}

#InfoSpeciality {
    font-size: 24px;
    color: #EF4765;
    font-weight: 500;
    margin-bottom: 2rem;
}

#InfoDesc {
    max-width: 500px;
    margin-bottom: 2rem;
    font-size: 18px;
}

#InfoSocial {
    display: flex;
    justify-content: space-between;
    width: 5rem;
    margin-bottom: 2rem;
}

#InfoSocial img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    object-position: center;
}

#InfoMe {
    margin-left: 5rem;
    max-width: 20rem;
    max-height: 40rem;
    width: 100%;

    position: relative;
    overflow: hidden;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/me-small.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 200ms ease-in-out;

    z-index: -10;
}

.background-overlay.hide {
    opacity: 0;
}

#InfoMeImg.loaded {
    opacity: 1;
}

#InfoMeImg {
    width: 100%;
    object-fit: cover;
    object-position: center;
    aspect-ratio: 2/3;
    
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

#InfoHireMeBtn {
    cursor: pointer;
    border: 0;
    border-radius: 5px;
    background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
    color: #FFFFFF;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5;
    padding: 0 1rem;
    text-align: center;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#InfoHireMeBtn:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.18);
}

#InfoGetCVBtn {
    margin-left: 1rem;

    cursor: pointer;
    border: 0;
    border-radius: 5px;
    background: white;
    color: #EF4765;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5;
    padding: 0 1rem;
    text-align: center;
    transition: box-shadow 0.2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#InfoGetCVBtn:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.18);
}

.SectionLine {
    border-bottom: 1px solid #c59aa2;
    width: 100%;
    margin: 5rem 0;
    opacity: .6;
}

#Skills {
    width: 100%;
}

#Skills p {
    margin-top: 1rem;
    margin-bottom: 3rem;
    font-size: 18px;
}

#SkillsLayout {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 2rem;
    margin: 0 auto 10rem auto;
}

.Skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Skill img {
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    object-position: center;
    margin-bottom: .5rem;
}

/* Responsive styles */
@media (max-width: 768px) {
    #Info {
        justify-content: center;
        text-align: center;
    }
    #InfoText {
        align-items: center;
    }
    #InfoSocial {
        justify-self: center;
    }
    #Skills {
        text-align: center;
    }
    #InfoMe {
        display: none;
    }
}