.MyPortfolio {
    width: 100%;
}

.PortfolioCategories{
    margin-bottom: 2rem;
    display: inline-flex;
    justify-content: center;
    width: 100%;
}

.PortfolioCategoryBtn {
    margin: 0 .4rem;
    
    border: 2px solid transparent;
    background: linear-gradient(to bottom right, #EF4765, #FF9A5A) padding-box,
    linear-gradient(to bottom right, #EF4765, #FF9A5A) border-box;
    border-radius: 5px;

    padding: 0 1rem;
    cursor: pointer;
    text-align: center;

    display: inline-block;
    font-weight: 500;
    line-height: 2;
    color: #FFFFFF;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    transition: box-shadow .2s ease-in-out;
}

.PortfolioCategoryBtn.Active {
    color: #EF4765;
    background: linear-gradient(#ffffff,#ffffff) padding-box,
    linear-gradient(to bottom right, #EF4765, #FF9A5A) border-box;
}

.PortfolioCategoryBtn:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.PortfolioCategoryBtn:active {
    background: linear-gradient(to bottom right, #eb2448, #fa8237) padding-box,
    linear-gradient(to bottom right, #eb2448, #fa8237) border-box;
    color: white;
}

.Title {
    animation: fadeIn 0.42s ease-in-out;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Title h2 {
    margin-bottom: 1rem;
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

.GridLayout {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin: 0 auto;
    max-width: 82rem;
}

.GridItem {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 40rem;
    max-height: 25rem;
    aspect-ratio: 4 / 2.5;
}

.LoadedImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    opacity: 0.5;
    animation: fadeInImageOnLoad 0.5s ease-in-out forwards;
}

@keyframes fadeInImageOnLoad {
    from {
        opacity: 0.5;
    }
    to {
        opacity: 1;
    }
}

.GridItemContent {
    width: 100%;
    height: 100%;
    top: 0;
    /* right: -100%; */
    position: absolute;
    background: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    color: white;
    transition: right 1s ease;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.GridItemContent.SlideOut {
    right: -100%;
}

.GridItem:hover .GridItemContent.SlideOut {
    right: 0;
}

.GridItemContent.Locked {
    right: 0;
}

#GridItemContentTitle {
    font-size: 28px;
    font-weight: 500;
    padding: 1rem 2rem;
    text-align: center;
}

#GridItemContentBtn {
  background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
  border: 0;
  border-radius: 5px;
  color: #FFFFFF;
  /* cursor: pointer; */
  display: inline-block;
  /* font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif; */
  /* font-size: 16px; */
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

#GridItemContentBtn:not([disabled]):hover {
    background: linear-gradient(to bottom right, #f12f52, #fc8942);
}

#GridItemContentBtn:not([disabled]):active {
    background: linear-gradient(to bottom right, #eb2448, #fa8237);
}


/* Responsive styles */
@media (max-width: 768px) {
    #GridItemContentTitle {
        font-size: 20px;
    }
}

@media (max-width: 1100px) { /* Adjust this breakpoint as needed */
    .GridLayout {
        grid-template-columns: 1fr; /* Switch to 1 column */
        max-width: 40rem;
    }
}