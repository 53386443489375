.Project {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    max-width: 70rem;
}

#ProjectTitle {
    margin-bottom: 2rem;
}

#ProjectPromotingDesc {
    margin-bottom: 2rem;
    align-self: flex-start;
}

#ProjectDetailsImgNLinks {
    display: flex;
    margin-bottom: 3rem;
    width: 100%;
}

#ProjectImg {
    max-width: 40rem;
    max-height: 27rem; /* ? */
    margin-right: 5rem;
}

#ProjectImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#ProjectDetails {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}

#ProjectDetailsCols{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 20rem;
}

.ProjectDetailsCol {
    margin-top: .5rem;
    text-decoration: underline;
    cursor: pointer;
}

#ProjectLiveView {
    background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
    cursor: pointer;
    border: 0;
    border-radius: 5px;
    color: #FFFFFF;
    display: inline-block;
    font-weight: 500;
    line-height: 2.5;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
}

#ProjectLiveView:not([disabled]):hover {
    background: linear-gradient(to bottom right, #f12f52, #fc8942);
    color: white;
}

#ProjectLiveView:not([disabled]):active {
    background: linear-gradient(to bottom right, #eb2448, #fa8237);
    color: white;
}

#ProjectDesc {
    width: 100%;
    margin-bottom: 3rem;
    font-size: larger;
}

#ProjectDesc h3 {
    margin-bottom: 1rem;
}

#ProjectTutorial {
    align-self: flex-start;
    width: 60%;
    margin-bottom: 3rem;
    font-size: larger;
}

#ProjectTutorial h3 {
    margin-bottom: 1rem;
}

#ProjectAppStatus {
    width: 100%;
    font-size: larger;
}

#ProjectAppStatus h3 {
    margin-bottom: 1rem;
}

#ProjectProgressBar {
    display: flex;
    flex-direction: column;
}

#ProjectProgressBarStatus {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    margin-bottom: 1rem;
}

#ProjectProgressBarPercentage {
    color: #EF4765;
}

#ProjectProgressBarFull {
    background-color: #c9c9c9;
    border-radius: 10px;
    height: .5rem;
    width: 100%;
    position: relative;
}

#ProjectProgressBarCompleted {
    position: absolute;
    height: .5rem;
    top: 0;
    left: 0;
    background-color: #EF4765;
    border-radius: 10px;
}

@media (max-width: 950px) { /* Adjust this breakpoint as needed */
    #ProjectDetailsImgNLinks {
        flex-direction: column;
        justify-content: center;
    }
    #ProjectImg {
        margin-bottom: 2rem;
        margin-right: 0;
        align-self: center;
    }
    #ProjectDetails {
        justify-content: center;
    }
    #ProjectTutorial {
        width: 100%;
    }
}