body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #555;
}

h1,h2,h3,h4,p {
  margin: 0;
}

p {
    font-size: 1.2rem;
    font-weight: 400;
}

a {
  /* text-decoration: none; */
  color: #555;
}

/* TODO Check */
:root {
  --mainColor: #555;
  --mainColorLight: #696969;
  --secondaryColor: #EF4765;
  --textColor: #555;
}


/* Theme Buttons */
.PrimaryBtn {
    border: 2px solid transparent;
    color: #FFFFFF;
    background: linear-gradient(to bottom right, #EF4765, #FF9A5A) padding-box,
    linear-gradient(to bottom right, #EF4765, #FF9A5A) border-box;
    border-radius: 5px;

    padding: 0 1rem;
    cursor: pointer;
    text-align: center;

    display: inline-block;
    font-weight: 500;
    line-height: 2;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
    transition: box-shadow .2s ease-in-out;
}

.PrimaryBtn:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.PrimaryBtn:active {
  background: linear-gradient(to bottom right, #eb2448, #fa8237) padding-box,
  linear-gradient(to bottom right, #eb2448, #fa8237) border-box;
  color: white;
}

.SecondaryBtn {
    border: 2px solid transparent;
    color: #EF4765;
    background: linear-gradient(#ffffff,#ffffff) padding-box,
    linear-gradient(to bottom right, #EF4765, #FF9A5A) border-box;
    border-radius: 5px;

    padding: 0 1rem;
    cursor: pointer;
    text-align: center;

    display: inline-block;
    font-weight: 500;
    line-height: 2;
    user-select: none;
    -webkit-user-select: none;
    white-space: nowrap;
}

.SecondaryBtn:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.SecondaryBtn:active {
  background: linear-gradient(to bottom right, #eb2448, #fa8237) padding-box,
  linear-gradient(to bottom right, #eb2448, #fa8237) border-box;
  color: white;
}