.skeleton-loader {
    width: 100%;
    height: 100%;
    background-color: #e0e0e0; /* Light gray */
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #ccc;
    }
    100% {
        background-color: #e0e0e0;
    }
}