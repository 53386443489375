.Navbar {
    display: flex;
    justify-content: space-between;
    padding: 0rem 5rem;
    box-shadow: 0px 0px 13px 3px rgba(0,0,0,0.2);
    position: relative;
    height: 5rem;
}

.NavbarLeft {
    display: flex;
    align-items: center;
}

.NavLinks {
    display: flex;
    align-items: center;
}

.Socials {
    width: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Socials {
    display: flex;
    align-items: center;
}

.Socials img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    cursor: pointer;

}

.HamburgerBtn {
    display: none;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 100%;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
}

.HamburgerBtn img {
    object-fit: contain;
    object-position: center;
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
}

.CloseBtn {
    display: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 100%;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
}

.CloseBtn img {
    object-fit: contain;
    object-position: center;
    border-radius: 100%;
    height: 1.5rem;
    width: 1.5rem;
}

.NavLink {
    position: relative;
    text-decoration: none;
    color: #555;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.5rem;
    margin: 0 .2rem;
}

.NavLink::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    background-color: #EF4765;
    left: 50%;
    bottom: 0;
    transition: width 0.4s ease, left 0.4s ease;
}

.NavLink.Active::after {
    width: 100%;
    left: 0;
}

.NavLink:hover::after {
    width: 100%;
    left: 0;
}

.Logo {
    margin-right: 3rem;
    height: 3rem;
    width: 3rem;
    border-radius: 100%;
    cursor: pointer;
    /* box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.3); */
}

.Logo img {
    object-fit: cover;
    object-position: center 40%;
    border-radius: 100%;
    height: 100%;
    width: 100%;
}
  

/* Responsive styles */
@media (max-width: 768px) {
    .NavLinks {
        display: none;
    }

    .NavLinks.Active {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .NavLink {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    
    .HamburgerBtn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .CloseBtn.Active {
        display: flex;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    .Logo {
        display: none;
    }
}
