.DarkBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

#Modal {
    margin: 2rem;
    height: max-content;
    width: 40rem;
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 10px;

    z-index: 1001;
}

#ModalHeader {
    height: 4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-weight: 600;
    font-size: 1.2rem;
}

#ModalHeaderCloseImg {
    height: 1.3rem;
    width: 1.3rem;
    cursor: pointer;
}

#ModalHeaderCloseImg img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

#ModalContent {
    flex: 1;
    /* width: 100%; */
    padding: 2rem;
    box-sizing: border-box;
}

#ModalFooter {
    height: 4rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}